import * as actions from '../constants'

const inititalState = {
  loadingJournals: true,
  loadingJournalsError: null,
  journals: [],
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_JOURNALS_REQUEST:
      return {
        ...state,
        journals: [],
        loadingJournals: true,
        loadingJournalsError: null
      }
    case actions.LOAD_JOURNALS_SUCCESS:
      return {
        ...state,
        journals:action.payload,
        loadingJournals: false,
        loadingJournalsError: null
      }
    case actions.LOAD_JOURNALS_FAILURE:
      return {
        ...state,
        loadingJournals: false,
        loadingJournalsError: action.payload
      }
    default:
      return state
  }
}
