import * as actions from '../constants'

export const loadJournals = () => dispatch=>{
  dispatch({
    type: actions.LOAD_JOURNALS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/journals?Visible=true`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_JOURNALS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_JOURNALS_FAILURE,
      payload: error.message
    })
  })
}