import React, { Component } from 'react';
import { connect } from 'react-redux'
import {loadJournals} from "../../actions/journals";
import './Journal.css'
import BodyClassName from 'react-body-classname';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import ReactMarkdown from 'react-markdown'
import {cdnUrl} from "../../constants";

class Journal extends Component {

  componentDidMount(){
    this.props.loadJournals()
  }

  renderContent() {
    let {journal} = this.props
    return <div className={'journal-md'}>
      <ReactMarkdown source={journal.content} />
    </div>
  }
  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  renderSwiper() {
    let self = this
    let {journal} = this.props
    if(!journal.slides || journal.slides.length === 0){
      return null
    }
    const params = {
      longSwipesRatio: 0.3,
      loop: true,
      // autoplay: {
      //   delay: 3000,
      //   speed: 1000,
      // },
    }
    return <div className={"journal-swiper"}>
      <Swiper {...params} ref={node => {
        if (node) {
          self.swiper = node.swiper
        }
      }}>
        {
          journal.slides.map((img, index) => {
            return <div key={index} style={{
                backgroundImage: `url(${cdnUrl(img.url)}?x-oss-process=image/resize,h_760,w_1104)`
              }}>
            </div>
          })
        }
      </Swiper>
      <div className={"prev swiper-arrow"} onClick={this.goPrev.bind(this)}>
      </div>
      <div className={"next swiper-arrow"} onClick={this.goNext.bind(this)}>
      </div>
    </div>
  }

  renderFooter(){
    let {journals} = this.props
    const params = {
      longSwipesRatio: 0.8,
      loop: true,
      slidesPerView: 3,
    }
    return <div className={'journal-footer'}>
      <div className={"journal-footer-swiper"}>
        <div>
        <Swiper {...params}>
          {
            journals.map((j, index) => {
              if(!j.image){
                return null
              }
              return <div key={index}>
                <div style={{
                  backgroundImage: `url(${cdnUrl(j.image.url)}?x-oss-process=image/resize,h_224,w_336)`
                }}>
                  <div onClick={(e)=>{
                    // e.preventDefault()
                  }}>
                    <Link to={`/journal/${j.id}`}>
                      <div className={"journal-footer-date"}>
                        {j.Date.split('T')[0]}
                      </div>
                      <div className={"journal-footer-title"}>
                        {j.title}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            })
          }
        </Swiper>
      </div>
      </div>
    </div>
  }

  render() {
    let {loadingJournals, loadingJournalsError, journal} = this.props
    if(loadingJournals){
      return <BodyClassName className={`journal`}>
        <div className={"loading-container"}>
          Loading
        </div>
      </BodyClassName>
    }else if (loadingJournalsError){
      return <BodyClassName className={`journal`}>
        <div className={"loading-error-container"}>
          {loadingJournalsError}
        </div>
      </BodyClassName>
    }else if (!journal){
      return <BodyClassName className={`journal`}>
        <div className={"loading-error-container"}>
          Not Found
        </div>
      </BodyClassName>
    }else{
      return <BodyClassName className={`journal`}>
        <div className="journal-container">
          <div className={"journal-date"}>
            {journal.Date?journal.Date.split('T')[0]:''}
          </div>
          <div className={"journal-title"}>
            {journal.title}
          </div>
          <div className={"journal-content"}>
            <div>
              {this.renderSwiper()}
              {this.renderContent()}
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </BodyClassName>
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  let {params} = ownProps.match
  let journal = null
  if(state.journals.journals && state.journals.journals.length>0){
    state.journals.journals.forEach(j=>{
      if(j.id === params.id){
        journal = j
      }
    })
  }
  return {
    journal,
    journals: state.journals.journals,
    loadingJournals: state.journals.loadingJournals,
    loadingJournalsError: state.journals.loadingJournalsError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadJournals: ()=>dispatch(loadJournals())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Journal)


