import * as actions from '../constants'

export const loadHomeSlides = () => dispatch=>{
  dispatch({
    type: actions.LOAD_HOMESLIDES_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/homeslides`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_HOMESLIDES_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_HOMESLIDES_FAILURE,
      payload: error.message
    })
  })
}