import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import windowSize from 'react-window-size';

import './App.css';
import Home from './routes/Home/Home'
import Information from './routes/Information/Information'
import Works from './routes/Works/Works'
import Journals from './routes/Journals/Journals'
import Journal from './routes/Journal/Journal'
import OpenIcon from './images/Menu'
import CloseIcon from './images/Close'



class App extends Component {
  state={
    openMobileMenu: false
  }
  render() {
    let {windowWidth, windowHeight} = this.props
    let params = {
      windowWidth,
      windowHeight
    }
    let self = this
    return (
      <div className={`app ${this.state.openMobileMenu?'app-menu-open':'app-menu-closed'} ${this.state.openMobileMenu ? 'app-menu-open' : 'app-menu-closed'}`}>
        <Router>
          <div>
            <Link to="/" className={"menu-home menu corner-menu"}>
              Huang Rui
            </Link>
            <Link to="/works" className={"menu-works menu corner-menu"}>
              Works
            </Link>
            <Link to="/information" className={"menu-information menu corner-menu"}>
              Information
            </Link>
            <Link to="/journal" className={"menu-journal menu corner-menu"}>
              Journal
            </Link>
            <div className={`mobile-menu ${this.state.openMobileMenu?'menu-open':'menu-closed'}`}>
              <div className={`menu-icon`}>
                <OpenIcon
                  onClick={()=>{
                    console.log('open')
                    self.setState({
                      openMobileMenu: true
                    })
                  }}
                  className={"menu-icon-open"}
                  width={'30px'}
                  height={'26px'}
                />
                <CloseIcon
                  className={"menu-icon-close"}
                  width={'20px'}
                  height={'26px'}
                  onClick={()=>{
                    console.log('close')
                    self.setState({
                      openMobileMenu: false
                    })
                  }}
                />
              </div>
              <Link to="/works" onClick={()=>{
                self.setState({
                  openMobileMenu: false
                })
              }} className={'works-nav'}>
                Works
              </Link>
              <Link to="/information" onClick={()=>{
                self.setState({
                  openMobileMenu: false
                })
              }} className={'information-nav'}>
                Information
              </Link>
              <Link to="/journal" onClick={()=>{
                self.setState({
                  openMobileMenu: false
                })
              }} className={'journal-nav'}>
                Journal
              </Link>
            </div>
            <div className={"content"}>
              <Route exact path="/" component={Home} />
              <Route exact path="/works" component={Works} />
              <Route exact path="/works/:module" component={Works} />
              <Route exact path="/journal" component={Journals} />
              <Route exact path="/journal/:id" component={Journal} />
              <Route exact path="/information" render={(props)=><Information
                { ...props }
                { ...params }
              />} />
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default windowSize(App);

