import { combineReducers } from 'redux';
import home from './home';
import information from './information'
import works from './works'
import journals from './journals'
import journal from './journal'

export default combineReducers({
  home,
  information,
  works,
  journals,
  journal
});