import * as actions from '../constants'

export const loadInformation = () => dispatch=>{

  dispatch({
    type: actions.LOAD_PAGES_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/pages`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_PAGES_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_PAGES_FAILURE,
      payload: error.message
    })
  })

  dispatch({
    type: actions.LOAD_SOLOEXHIBITIONS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/soloexhibitions`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_SOLOEXHIBITIONS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_SOLOEXHIBITIONS_FAILURE,
      payload: error.message
    })
  })

  dispatch({
    type: actions.LOAD_GROUPEXHIBITIONS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/groupexhibitions`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_GROUPEXHIBITIONS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_GROUPEXHIBITIONS_FAILURE,
      payload: error.message
    })
  })

  dispatch({
    type: actions.LOAD_PUBLICATIONS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/publications`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_PUBLICATIONS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_PUBLICATIONS_FAILURE,
      payload: error.message
    })
  })

  dispatch({
    type: actions.LOAD_WRITINGS_REQUEST
  })
  fetch(`${actions.API_ENDPOINT}/writings`).then(res=> {
    return res.json()
  }).then(data=>{
    dispatch({
      type: actions.LOAD_WRITINGS_SUCCESS,
      payload: data
    })
  }).catch(error=>{
    dispatch({
      type: actions.LOAD_WRITINGS_FAILURE,
      payload: error.message
    })
  })

}