import React from 'react'

const Menu = (props) => <svg
  width="68px"
  height="36px"
  viewBox="0 0 68 36"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  strokeWidth={3}
  stroke={"#000000"}
  {...props}
>
  <path d="M0,32 L68,32" ></path>
  <path d="M0,0 L68,0"></path>
  <path d="M0,16 L68,16"></path>
</svg>

export default Menu;