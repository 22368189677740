import React, { Component } from 'react';
import { connect } from 'react-redux'
import {loadJournals} from "../../actions/journals";
import './Journals.css'
import BodyClassName from 'react-body-classname';
import {cdnUrl} from "../../constants";
import { Link } from 'react-router-dom';

class Journals extends Component {

  componentDidMount(){
    this.props.loadJournals()
  }

  renderJournalsGrid() {
    let {journals} = this.props
    return <div>
      {
        journals && journals.map((journal, index) => {
          return <div className={"journal-grid"} key={journal.id}>
            <Link to={`/journal/${journal.id}`} className={"journal-cover"} style={{
              backgroundImage: journal.image?`url(${cdnUrl(journal.image.url)}?x-oss-process=image/resize,h_480,w_720)`:'none'
            }}>
            </Link>
            <div className={"journal-date"}>
              {journal.Date?journal.Date.split('T')[0]:''}
            </div>
            <div className={"journal-title"}>
              {journal.title || ''}
            </div>
            <div className={"journal-summary"}>
              {journal.content?journal.content.slice(0, 120):''}...
              <Link to={`/journal/${journal.id}`} className={'read-more'}>Read More</Link>
            </div>
          </div>
        })
      }
    </div>
  }

  render() {
    let {loadingJournals, loadingJournalsError, data} = this.props
    if(loadingJournals){
      return <BodyClassName className={`journals`}>
        <div className={"loading-container"}>
          Loading
        </div>
      </BodyClassName>
    }else if (loadingJournalsError){
      return <BodyClassName className={`journals`}>
        <div className={"loading-error-container"}>
          {loadingJournalsError}
        </div>
      </BodyClassName>
    }else{
      return <BodyClassName className={`journals`}>
        <div className="journals-container">
          <div className={"journals-grids"}>
            <div>
              {this.renderJournalsGrid()}
            </div>
          </div>
          <div className={"journals-banner"} />
        </div>
      </BodyClassName>
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    journals: state.journals.journals,
    loadingJournals: state.journals.loadingJournals,
    loadingJournalsError: state.journals.loadingJournalsError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadJournals: ()=>dispatch(loadJournals())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Journals)


