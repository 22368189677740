import React, { Component } from 'react';
import { connect } from 'react-redux'
import {loadWorks} from "../../actions/works";
import './Works.css'
import BodyClassName from 'react-body-classname';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Swiper from 'react-id-swiper';
import Close from '../../images/Close'
import {cdnUrl} from "../../constants";
import RightArrow from '../../images/RightArrow'
import LeftArrow from '../../images/LeftArrow'

Modal.setAppElement('#modal')

class Works extends Component {

  state = {
  }

  componentDidMount(){
    let self = this
    self.props.loadWorks()
  }

  renderNavTitle(){
    let {modules, match} = this.props
    let active = match.params.module || (modules[0]?modules[0].id:null)
    return <div className={"works-module-nav"}>
      {
        modules.map((module, index) => {
          return <Link className={`${module.id === active ? "active" : ""}`} to={`/works/${module.id}`} key={index}>
            {module.title+(index!==modules.length-1?',':'')}
          </Link>
        })
      }
    </div>
  }
  renderMobileNavTitle(){
    let {modules, match} = this.props
    let active = match.params.module || (modules[0]?modules[0].id:null)
    return <div className={"works-module-mobile-nav"}>
      {
        modules.map((module, index) => {
          return <Link className={`${module.id === active ? "active" : ""}`} to={`/works/${module.id}`} key={index}>
            {module.title}
          </Link>
        })
      }
    </div>
  }

  renderWorksGrid(){
    let {works, modules, match} = this.props
    let active = match.params.module || (modules[0]?modules[0].id:null)
    if(!works[active]){
      return null
    }
    return <div className={"works-grids"}>
      {
        works[active].map((work, index)=>{
          let title = work.title || 'work'
          if(work.year){
            title +=', '+work.year
          }
          if(work.media){
            title +=', '+work.media
          }
          if(work.dimension){
            title +=', '+work.dimension
          }
          let self = this
          return <div className={"works-grid"} key={index} onClick={()=>{
            self.onMouseMove()
            self.setState({
              modal: {
                works: works[active],
                index
              }
            })
          }} onMouseEnter={()=>{
            self.setState({
              activeWork: work,
            })
          }} onMouseLeave={()=>{
            self.setState({
              activeWork: null,
            })
          }}>
            <div className={"cover"} style={{
              backgroundImage:`url('${cdnUrl(work.image.url)}?x-oss-process=image/resize,h_600,w_672')`
            }} />
            {/*<div className={"title"}>*/}
            {/*{title}*/}
            {/*</div>*/}
          </div>
        })
      }
      <div />
    </div>
  }

  getTitle(work){
    let title = work.title || 'work'
    if(work.year){
      title +=', '+work.year
    }
    if(work.media){
      title +=', '+work.media
    }
    if(work.dimension){
      title +=', '+work.dimension
    }
    return title
  }

  renderSwiper() {
    const self = this
    if(!self.state.modal){
      return null
    }
    let {works, index}= self.state.modal
    const params = {
      longSwipesRatio: 0.3,
      loop: true,
      on: {
        slideChange: () => {
          if (self.swiper) {
            self.setState({
              modal: {
                ...self.state.modal,
                index: self.swiper.activeIndex,
              }
            })
          }
        }
      },
      initialSlide: index,
    }
    return <Swiper {...params} ref={node => {
      if (node) {
        self.swiper = node.swiper
      }
    }}>
      {
        works.map((work, index) => {
          return <div key={index}>
            <div className="image" style={{
              backgroundImage: `url(${cdnUrl(work.image.url)}?x-oss-process=image/resize,h_1520,w_2208)`
            }}>
            </div>
            <div className="title">
              {this.getTitle(work)}
            </div>
          </div>
        })
      }
    </Swiper>
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  onMouseMove(){
    this.setState({
      mouseMoving: true,
    })
    if(this.timer){
      clearTimeout(this.timer)
    }
    let self=this
    this.timer = setTimeout(()=>{
      self.setState({
        mouseMoving: false
      })
      self.timer = null
    }, 1000)
  }

  render() {
    let {loadingWorks, loadingWorksError, data} = this.props
    if(loadingWorks){
      return <BodyClassName className={`works`}>
        <div className={"loading-container"}>
          Loading
        </div>
      </BodyClassName>
    }else if (loadingWorksError){
      return <BodyClassName className={`works`}>
        <div className={"loading-error-container"}>
          {loadingWorksError}
        </div>
      </BodyClassName>
    }else{
      let self = this
      const customStyles = {
        content : {
          top                   : '50%',
          left                  : '50%',
          transform             : 'translate(-50%, -50%)',
          zIndex: 10000,
          width: '100%',
          height: '100%',
          background: 'white',
        }
      };
      return <BodyClassName className={`works ${self.state.modal?'show-modal':''}`}>
        <div className="works-container">
          {!self.state.modal && this.renderNavTitle()}
          {!self.state.modal && this.renderMobileNavTitle()}
          {!self.state.modal && this.renderWorksGrid()}
          {!self.state.modal && <div className={"active-work-tile"}>
            {self.state.activeWork?self.getTitle(self.state.activeWork):''}
          </div>
          }
          <Modal
            overlayClassName={'works-gallery-overlay'}
            className={'works-modal-wrapper-open'}
            isOpen={!!self.state.modal}
            // onRequestClose={()=>{
            //   self.setState({
            //     modal: null
            //   })
            // }}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className={`works-modal-wrapper ${this.state.mouseMoving?'mouse-moving':''}`}
              onMouseMove={this.onMouseMove.bind(this)}
            >
              {this.renderSwiper()}
              <div className={"prev swiper-arrow"} onClick={this.goPrev.bind(this)}>
              </div>
              <div className={"next swiper-arrow"} onClick={this.goNext.bind(this)}>
              </div>
              <div className={"works-modal-close"} onClick={()=>{
                self.setState({modal:null})
              }}>
                <Close/>
              </div>
            </div>
          </Modal>
        </div>
      </BodyClassName>
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    works: state.works.works,
    modules: state.works.modules,
    loadingWorks: state.works.loadingWorks,
    loadingWorksError: state.works.loadingWorksError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWorks: ()=>dispatch(loadWorks())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Works)


