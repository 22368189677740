import * as actions from '../constants'

const inititalState = {
  loadingJournal: true,
  loadingJournalError: null,
  journal: {},
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_JOURNAL_REQUEST:
      return {
        ...state,
        journal: {},
        loadingJournal: true,
        loadingJournalError: null
      }
    case actions.LOAD_JOURNAL_SUCCESS:
      if(action.payload.length == 0){
        return {
          ...state,
          journal:{},
          loadingJournal: false,
          loadingJournalError: 'Not Found'
        }
      }else{
        return {
          ...state,
          journal:action.payload[0],
          loadingJournal: false,
          loadingJournalError: null
        }
      }
    case actions.LOAD_JOURNAL_FAILURE:
      return {
        ...state,
        loadingJournal: false,
        loadingJournalError: action.payload
      }
    default:
      return state
  }
}
