export const API_ENDPOINT = "http://8.210.233.31:21337"
export const CDN_ENDPOINT = "http://huangrui-media.jilinchen.com"
export const OSS_ENDPOINT = "http://huangrui.oss-cn-beijing.aliyuncs.com"

export function cdnUrl(url){
  return url.replace(OSS_ENDPOINT, CDN_ENDPOINT)
}
export const LOAD_HOMESLIDES_REQUEST = 'LOADING_HOMESLIDES_REQUEST'
export const LOAD_HOMESLIDES_FAILURE = 'LOADING_HOMESLIDES_FAILURE'
export const LOAD_HOMESLIDES_SUCCESS = 'LOADING_HOMESLIDES_SUCCESS'

export const LOAD_INFORMATION_REQUEST = 'LOADING_INFORMATION_REQUEST'
export const LOAD_INFORMATION_FAILURE = 'LOADING_INFORMATION_FAILURE'
export const LOAD_INFORMATION_SUCCESS = 'LOADING_INFORMATION_SUCCESS'

export const LOAD_WORKS_REQUEST = 'LOADING_WORKS_REQUEST'
export const LOAD_WORKS_FAILURE = 'LOADING_WORKS_FAILURE'
export const LOAD_WORKS_SUCCESS = 'LOADING_WORKS_SUCCESS'

export const LOAD_JOURNALS_REQUEST = 'LOADING_JOURNALS_REQUEST'
export const LOAD_JOURNALS_FAILURE = 'LOADING_JOURNALS_FAILURE'
export const LOAD_JOURNALS_SUCCESS = 'LOADING_JOURNALS_SUCCESS'

export const LOAD_JOURNAL_REQUEST = 'LOADING_JOURNAL_REQUEST'
export const LOAD_JOURNAL_FAILURE = 'LOADING_JOURNAL_FAILURE'
export const LOAD_JOURNAL_SUCCESS = 'LOADING_JOURNAL_SUCCESS'

export const LOAD_PAGES_REQUEST = 'LOADING_PAGES_REQUEST'
export const LOAD_PAGES_FAILURE = 'LOADING_PAGES_FAILURE'
export const LOAD_PAGES_SUCCESS = 'LOADING_PAGES_SUCCESS'

export const LOAD_WRITINGS_REQUEST = 'LOADING_WRITINGS_REQUEST'
export const LOAD_WRITINGS_FAILURE = 'LOADING_WRITINGS_FAILURE'
export const LOAD_WRITINGS_SUCCESS = 'LOADING_WRITINGS_SUCCESS'

export const LOAD_PUBLICATIONS_REQUEST = 'LOADING_PUBLICATIONS_REQUEST'
export const LOAD_PUBLICATIONS_FAILURE = 'LOADING_PUBLICATIONS_FAILURE'
export const LOAD_PUBLICATIONS_SUCCESS = 'LOADING_PUBLICATIONS_SUCCESS'

export const LOAD_SOLOEXHIBITIONS_REQUEST = 'LOADING_SOLOEXHIBITIONS_REQUEST'
export const LOAD_SOLOEXHIBITIONS_FAILURE = 'LOADING_SOLOEXHIBITIONS_FAILURE'
export const LOAD_SOLOEXHIBITIONS_SUCCESS = 'LOADING_SOLOEXHIBITIONS_SUCCESS'

export const LOAD_GROUPEXHIBITIONS_REQUEST = 'LOADING_GROUPEXHIBITIONS_REQUEST'
export const LOAD_GROUPEXHIBITIONS_FAILURE = 'LOADING_GROUPEXHIBITIONS_FAILURE'
export const LOAD_GROUPEXHIBITIONS_SUCCESS = 'LOADING_GROUPEXHIBITIONS_SUCCESS'

