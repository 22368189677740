import * as actions from '../constants'

const inititalState = {
  loadingHomeSlides: true,
  loadingHomeSlidesError: null,
  data: []
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_HOMESLIDES_REQUEST:
      return {
        ...state,
        data:[],
        loadingHomeSlides: true,
        loadingHomeSlidesError: null
      }
    case actions.LOAD_HOMESLIDES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingHomeSlides: false,
        loadingHomeSlidesError: null
      }
    case actions.LOAD_HOMESLIDES_FAILURE:
      return {
        ...state,
        loadingHomeSlides: false,
        loadingHomeSlidesError: action.payload
      }
    default:
      return state
  }
}
