import React, { Component } from 'react';
import { connect } from 'react-redux'
import {loadInformation} from "../../actions/information";
import './Information.css'
import BodyClassName from 'react-body-classname';
import ReactMarkdown from 'react-markdown'
import {debounce} from 'lodash'
import {cdnUrl} from "../../constants";
import TWEEN from '@tweenjs/tween.js'




class Information extends Component {
  state = {
  }

  componentDidMount(){
    let self = this
    self.props.loadInformation()
  }

  renderNavTitle(){
    let navs = ['biography', 'exhibitions', 'publications', 'writings', 'contact', 'credits']
    let {active} = this.state
    if(!active) {
      active = 'biography'
    }
    let self = this
    return <div className={"information-navs"}>
      {
        navs.map((nav, index) => {
          return <div key={index}>
            <a
              href={`#${nav}`}
              className={`${nav === active ? "active" : ""}`}
              onClick={()=>{self.setState({active: nav})}}
            >
            {nav}
          </a>{index===navs.length-1?'':','}</div>
        })
      }
    </div>
  }

  renderMobileNavTitle(){
    let navs = ['biography', 'exhibitions', 'publications', 'writings', 'contact', 'credits']
    let {active} = this.state
    if(!active) {
      active = 'biography'
    }
    let self = this
    return <div className={"information-mobile-navs"}>
      {
        navs.map((nav, index) => {
          return <div key={index}>
            <a
              href={`#${nav}`}
              className={`${nav === active ? "active" : ""}`}
              onClick={()=>{self.setState({active: nav})}}
            >
              {nav}
            </a>
          </div>
        })
      }
    </div>
  }


  renderExhibitions(exhibitions){
    return exhibitions.map(ex=>{
      return <div className={"exhibition-item"} key={ex.id}>
        <div>{ex.year}</div>
        <div>{ex.exhibitions}</div>
      </div>
    })
  }

  renderPublications(){
    let {publications} = this.props
    return publications.map(p=><div className={"publication-item"} key={p.id}>
      <a href={`${p.pdf && cdnUrl(p.pdf.url)}`} target="_blank">{p.title}</a>
      <div>{`${p.publisher},${p.location},${p.year}`}</div>
    </div>)
  }

  renderWritings(){
    let {writings} = this.props
    return writings.map(p=><div className={"writing-item"} key={p.id}>
      <a href={`${p.pdf && cdnUrl(p.pdf.url)}`} target="_blank">{p.title}</a>
      <span>{` By ${p.author}`}</span>
    </div>)
  }


  handleScroll() {
    let navs = ['biography', 'exhibitions', 'publications', 'writings', 'contact', 'credits']
    let elements = [
      this.biography,
      this.exhibitons,
      this.publications,
      this.writings,
      this.contact,
      this.credits,
    ]
    let self = this
    let scrollTop = this.container.scrollTop
    elements.forEach((element, index)=>{
      if(scrollTop>element.offsetTop && scrollTop<element.offsetTop+element.offsetHeight){
          self.setState({active:navs[index]})
      }
    })
  }

  render() {
    let {windowHeight, windowWidth, loading, loadingError, pages, publications, writings, soloExhibitions, groupExhibitions} = this.props
    let biography = pages['biography']
    let contact = pages['contact']
    let credits = pages['credits']
    if(loading){
      return <BodyClassName className={`information`}>
        <div className={"loading-container"}>
          Loading
        </div>
      </BodyClassName>
    }else if (loadingError){
      return <BodyClassName className={`information`}>
        <div className={"loading-error-container"}>
          {loadingError}
        </div>
      </BodyClassName>
    }else{
      return <BodyClassName className={`information`}>
        <div className="information-container">
          {this.renderNavTitle()}
          {this.renderMobileNavTitle()}
          <div
            className={"information-content"}
            onScroll={this.handleScroll.bind(this)}
            ref={ref=>this.container=ref}
          >
            {biography && <div
              className={"information-snap-box"}
              id={"biography"}
              style={{
                minHeight:windowHeight+3
              }}
              ref={ref=>this.biography=ref}
            >
              <div className={"cover-container"}>
                <img src={`${cdnUrl(biography.image.url)}?x-oss-process=image/resize,h_480,w_720`}/>
              </div>
              <div className={"content-container"}>
                {biography.content.split("\n").map((l, index) => {
                  return <div key={index}>{l}</div>
                })}
              </div>
            </div>
            }
            {(soloExhibitions && groupExhibitions) && <div
              className={"information-snap-box"}
              id={"exhibitions"}
              style={{
                minHeight:windowHeight+3
              }}
              ref={ref=>this.exhibitons=ref}
            >
              <div className={"content-container"}>
                <div className={"content-solo-exhibitions"}>
                  <div className={"exhibition-title"}>
                    Solo Exhibitions
                  </div>
                  {this.renderExhibitions(soloExhibitions)}
                </div>
                <div className={"content-group-exhibitions"}>
                  <div className={"exhibition-title"}>
                    Group Exhibitions
                  </div>
                  {this.renderExhibitions(groupExhibitions)}
                </div>
              </div>
            </div>
            }
            {(publications) && <div
              className={"information-snap-box"}
              id={"publications"}
              style={{
                minHeight:windowHeight+3
              }}
              ref={ref=>this.publications=ref}
            >
              <div className={"content-container"}>
                {this.renderPublications()}
              </div>
            </div>
            }
            {(writings) && <div
              className={"information-snap-box"}
              id={"writings"}
              style={{
                minHeight:windowHeight+3
              }}
              ref={ref=>this.writings=ref}
            >
              <div className={"content-container"}>
                {this.renderWritings()}
              </div>
            </div>
            }
            {contact && <div
              className={"information-snap-box"}
              id={"contact"}
              style={{
                minHeight: windowHeight+3
              }}
              ref={ref=>this.contact=ref}
            >
              <div className={"content-container"}>
                <ReactMarkdown source={contact.content} />
              </div>
            </div>
            }
            {credits && <div
              className={"information-snap-box"}
              id={"credits"}
              style={{
                minHeight: windowHeight+3
              }}
              ref={ref=>this.credits=ref}
            >
              <div className={"content-container"}>
                <ReactMarkdown source={credits.content} />
              </div>
            </div>
            }
          </div>
        </div>
      </BodyClassName>
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    pages: state.information.pages,
    publications: state.information.publications,
    writings: state.information.writings,
    soloExhibitions: state.information.soloExhibitions,
    groupExhibitions: state.information.groupExhibitions,
    loading: state.information.loadingPages || state.information.loadingPublications || state.information.loadingWritings || state.information.loadingSoloExhibitions || state.information.loadingGroupExhibitions,
    loadingError: state.information.loadingPagesError || state.information.loadingPublicationsError || state.information.loadingWritingsError || state.information.loadingGroupExhibitionsError || state.information.loadingSoloExhibitionsError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadInformation: ()=>dispatch(loadInformation())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Information)


