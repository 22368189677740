import * as actions from '../constants'

const inititalState = {

  loadingPages: true,
  loadingPagesError: null,
  pages: {},

  loadingPublications: true,
  loadingPublicationsError: null,
  publications: [],

  loadingWritings: true,
  loadingWritingsError: null,
  writings: [],

  loadingGroupExhibitions: true,
  loadingGroupExhibitionsError: null,
  groupExhibitions: [],

  loadingSoloExhibitions: true,
  loadingSoloExhibitionsError: null,
  soloExhibitions: [],

}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_PAGES_REQUEST:
      return {
        ...state,
        pages:{},
        loadingPages: true,
        loadingPagesError: null
      }
    case actions.LOAD_PAGES_SUCCESS:
      let pages = {}
      action.payload.forEach(page=>{
        pages[page.title.toLowerCase()] = page
      })
      return {
        ...state,
        pages,
        loadingPages: false,
        loadingPagesError: null
      }
    case actions.LOAD_PAGES_FAILURE:
      return {
        ...state,
        loadingPages: false,
        loadingPagesError: action.payload
      }

    case actions.LOAD_PUBLICATIONS_REQUEST:
      return {
        ...state,
        publications:[],
        loadingPublications: false,
        loadingPublicationsError: null
      }
    case actions.LOAD_PUBLICATIONS_SUCCESS:
      return {
        ...state,
        publications: action.payload,
        loadingPublications: false,
        loadingPublicationsError: null
      }
    case actions.LOAD_PUBLICATIONS_FAILURE:
      return {
        ...state,
        loadingPublications: false,
        loadingPublicationsError: action.payload
      }

    case actions.LOAD_WRITINGS_REQUEST:
      return {
        ...state,
        writings:[],
        loadingWritings: false,
        loadingWritingsError: null
      }
    case actions.LOAD_WRITINGS_SUCCESS:
      return {
        ...state,
        writings: action.payload,
        loadingWritings: false,
        loadingWritingsError: null
      }
    case actions.LOAD_WRITINGS_FAILURE:
      return {
        ...state,
        loadingWritings: false,
        loadingWritingsError: action.payload
      }

    case actions.LOAD_SOLOEXHIBITIONS_REQUEST:
      return {
        ...state,
        soloExhibitions:[],
        loadingSoloExhibitions: false,
        loadingSoloExhibitionsError: null
      }
    case actions.LOAD_SOLOEXHIBITIONS_SUCCESS:
      return {
        ...state,
        soloExhibitions: action.payload,
        loadingSoloExhibitions: false,
        loadingSoloExhibitionsError: null
      }
    case actions.LOAD_SOLOEXHIBITIONS_FAILURE:
      return {
        ...state,
        loadingSoloExhibitions: false,
        loadingSoloExhibitionsError: action.payload
      }

    case actions.LOAD_GROUPEXHIBITIONS_REQUEST:
      return {
        ...state,
        groupExhibitions:[],
        loadingGroupExhibitions: false,
        loadingGroupExhibitionsError: null
      }
    case actions.LOAD_GROUPEXHIBITIONS_SUCCESS:
      return {
        ...state,
        groupExhibitions: action.payload,
        loadingGroupExhibitions: false,
        loadingGroupExhibitionsError: null
      }
    case actions.LOAD_GROUPEXHIBITIONS_FAILURE:
      return {
        ...state,
        loadingGroupExhibitions: false,
        loadingGroupExhibitionsError: action.payload
      }



    default:
      return state
  }
}
