import React, { Component } from 'react';
import { connect } from 'react-redux'
import {loadHomeSlides} from "../../actions/home";
import './Home.css'
import BodyClassName from 'react-body-classname';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import {cdnUrl} from "../../constants";
import RightArrow from '../../images/RightArrow'
import LeftArrow from '../../images/LeftArrow'



class Home extends Component {
  state = {
    showEntry: true,
    activeIndex: 0,
  }

  componentDidMount(){
    let self = this
    self.props.loadHomeSlides()
    setTimeout(()=>{
       self.setState({
         showEntry: false
       })
    }, 300)
  }

  renderSwiper() {
    const self = this
    const params = {
      longSwipesRatio: 0.3,
      loop: true,
      speed: 1000,
      centeredSlides: true,
      on: {
        slideChange: () => {
          if(self.swiper){
            self.setState({
              activeIndex: self.swiper.activeIndex
            })
          }
        }
      }
    }
    return <Swiper {...params} ref={node => {
      if(node){
        self.swiper = node.swiper
      }
    }}>
      {
        this.props.data.map((hs, index)=>{
          let href=null
          if(hs.work && hs.work.id){
            href="/work/"+hs.work.id
          }else if(hs.link != ""){
            href = hs.link
          }
          return <div key={index}>
            {!!href &&
            <div className={"slide-img"} to={href} style={{
              backgroundImage: `url(${cdnUrl(hs.image.url)}?x-oss-process=image/resize,h_760,w_1104)`
            }}>
            </div>
            }
            {!href &&
            <div className={"slide-img"} style={{
              backgroundImage: `url(${cdnUrl(hs.image.url)}?x-oss-process=image/resize,h_760,w_1104)`
            }}>
            </div>
            }
          </div>
        })
      }
    </Swiper>
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  onMouseMove(){
    this.setState({
      mouseMoving: true,
    })
    if(this.timer){
      clearTimeout(this.timer)
    }
    let self=this
    this.timer = setTimeout(()=>{
      self.setState({
        mouseMoving: false
      })
      self.timer = null
    }, 1000)
  }

  componentWillUnmount(){
    if(this.timer){
      clearTimeout(this.timer)
    }
  }


  render() {
    let {loadingHomeSlides, loadingHomeSlidesError, match} = this.props
    let {showEntry} = this.state
    if(showEntry) {
      return <BodyClassName className={`${showEntry?'show-entry home':'home'}`}>
        <div className={"home-entry"}>
          Huang Rui
        </div>
      </BodyClassName>
    } else if(loadingHomeSlides){
      return <BodyClassName className={`home`}>
        <div className={"loading-container"}>
          Loading
        </div>
      </BodyClassName>
    }else if (loadingHomeSlidesError){
      return <BodyClassName className={`home`}>
        <div className={"loading-error-container"}>
          {loadingHomeSlidesError}
        </div>
      </BodyClassName>
    }else{
      let href=false
      let hs = this.props.data[this.state.activeIndex]
      if(hs && hs.work && hs.work.id){
        href="/work/"+hs.work.id
      }else if(hs && hs.link != ""){
        href = hs.link
      }
      return <BodyClassName className={`home`}>
        <div
          className={`home-content ${this.state.mouseMoving?'mouse-moving':''}`}
          onMouseMove={this.onMouseMove.bind(this)}
        >
          {this.renderSwiper()}
          {(href && hs && hs.title && hs.title.trim() !== "") &&
          <Link to={href} className={"slide-title"}>
            {this.props.data[this.state.activeIndex] ? this.props.data[this.state.activeIndex].title : ""}
          </Link>
          }
          {(!href && hs && hs.title && hs.title.trim() !== "") &&
          <div className={"slide-title"}>
            {hs.title}
          </div>
          }
          <div className={"prev swiper-arrow"} onClick={this.goPrev.bind(this)}>
          </div>
          <div className={"next swiper-arrow"} onClick={this.goNext.bind(this)}>
          </div>
        </div>
      </BodyClassName>
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.home.data,
    loadingHomeSlides: state.home.loadingHomeSlides,
    loadingHomeSlidesError: state.home.loadingHomeSlidesError,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadHomeSlides: ()=>dispatch(loadHomeSlides())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)


