import * as actions from '../constants'

const inititalState = {
  loadingWorks: true,
  loadingWorksError: null,
  works: {},
  modules: [],
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actions.LOAD_WORKS_REQUEST:
      return {
        ...state,
        works: {},
        modules: [],
        loadingWorks: true,
        loadingWorksError: null
      }
    case actions.LOAD_WORKS_SUCCESS:
      let works = {}
      let modulesMap = {}
      action.payload.forEach(work=>{
        if(work.module && work.module.id){
          if(!works[work.module.id]) {
            works[work.module.id] = [work]
          }else{
            works[work.module.id].push(work)
          }
          modulesMap[work.module.id] = work.module
        }
      })
      let modules = Object.keys(modulesMap).map(key=>{
        return modulesMap[key]
      }).sort((a, b)=>a['display-order'] > b['display-order'] ? -1: 1)
      Object.keys(works).forEach(key=>{
        works[key] = works[key].sort(
          (a,b)=>a['display-order'] > b['display-order'] ? 1: -1
        )
      })
      return {
        ...state,
        works,
        modules,
        loadingWorks: false,
        loadingWorksError: null
      }
    case actions.LOAD_WORKS_FAILURE:
      return {
        ...state,
        loadingWorks: false,
        loadingWorksError: action.payload
      }
    default:
      return state
  }
}
